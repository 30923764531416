/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef, useState} from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  // defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  // messageFromClient,
} from '../../../../../_metronic/helpers'
import {useEffect} from 'react'
import GetAlert from '../../../../pages/alert/GetAlert'
import {useAuth} from '../../../auth'
import {calculatePassTime} from '../../../../helpers/dateUtils'
import {getStreamWithFetch} from '../core/_request'
import moment from 'moment'
import {FullScreen, FullScreenHandle} from 'react-full-screen'
import { on } from 'events'
import {cleanMessage} from "../../../../../common/utils/domPurify";

type Props = {
  isDrawer?: boolean
  onSendMessage?: (message: string, callback?: () => void) => void
  messages?: MessageModel[]
  file?: File | null
  onFileChange?: (e: any) => void
  isTextSent?: string
  onRemoveFile?: () => void
  loading?: boolean
  onSwitchFullScreen?: () => void
  isFullScreen?: boolean
  fscreenHandle?: FullScreenHandle
  onDownLoaded?: () => void
  isMobile?: boolean
  fsHandle: FullScreenHandle
  fsReportChange?: (state: boolean, handle: FullScreenHandle) => void
}

const ChatCustom: FC<Props> = ({
  isDrawer = false,
  onSendMessage,
  messages = [],
  file,
  onFileChange,
  isTextSent = '',
  onRemoveFile,
  loading,
  onSwitchFullScreen,
  isFullScreen = false,
  fscreenHandle,
  onDownLoaded,
  isMobile,
  fsHandle,
  fsReportChange,
}) => {
  const [message, setMessage] = useState<string>('')
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const bottomRef: any = useRef(null)
  const [open, setOpen] = useState<boolean>(false)
  const {currentUser} = useAuth()
  const inputFile: any = useRef(null)
  const scrollableRef: any = useRef(null)
  const inputRef: any = useRef()

  const sendMessage = () => {
    if (onSendMessage) {
      onSendMessage(message, () => {
        console.log('Call the callback function', inputRef?.current)
        setTimeout(() => {
          inputRef?.current?.focus()
        }, 500)
      })
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()    
    }
  }


  const onClickFileButton = (e: any) => {
    inputFile?.current?.click()
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     bottomRef.current?.scrollIntoView({behavior: 'smooth'})
  //   }, 500)
  // }, [isTextSent])

  useEffect(() => {
    console.log('isTextSent === message', isTextSent, message)
    if (isTextSent === message) {
      setMessage('')
    }
  }, [isTextSent])

  useEffect(() => {
    if (scrollableRef) {
      console.log('scrollableRef', scrollableRef)
      if (scrollableRef?.current?.scrollHeight) {
        if (bottomRef) {
          setTimeout(() => {
            bottomRef?.current?.scrollIntoView({behavior: 'smooth'})
          }, 500)
        }
      }
    }
  }, [scrollableRef, bottomRef, messages])

  return (
    <div className='d-flex flex-column' style={{height: '100%', maxHeight: '100%'}}>
      <div
        className={'d-flex justify-content-end align-items-center p-2 ' + (isFullScreen ? ' ' : '')}
       >
        <div
          className={'position-relative cursor-pointer overflow-hidden'}
          onClick={() => {
            if (
              (currentUser?.Profile === "Lanceur d'alerte" || currentUser?.Profile === 'Juriste') &&
              !isFullScreen
            ) {
              setOpen(true)
            }
          }}
          style={{
            cursor:
              currentUser?.Profile !== "Lanceur d'alerte" && currentUser?.Profile !== 'Juriste'
                ? 'not-allowed'
                : 'pointer',
            opacity:
              currentUser?.Profile !== "Lanceur d'alerte" && currentUser?.Profile !== 'Juriste'
                ? 0.5
                : 1,
            width: isFullScreen ? 0 : 'auto',
            height: isFullScreen ? 0 : 'auto',
          }}
          data-container="body" data-toggle="tooltip" data-placement="bottom" title="Résumé de l'alerte"
        >
          <img
            src={toAbsoluteUrl('/media/images/alert_icone.png')}
            alt='Logo'
            style={{width: '30px'}}
          />
          <div
            className={'position-absolute d-flex align-items-center justify-content-center'}
          ></div>
        </div>
        <div className='mx-6 cursor-pointer' data-container="body" data-toggle="tooltip" data-placement="bottom" title="Actualiser" onClick={onDownLoaded}>
          <img src={toAbsoluteUrl('/media/images/btn_actualisation.png')} style={{width: '30px'}} />
        </div>
        {!isFullScreen && (
          <div
            onClick={() => {
              if (onSwitchFullScreen) {
                onSwitchFullScreen()
              }
              if (fscreenHandle) {
                fscreenHandle.enter()
              }
            }}
            className={'cursor-pointer'}
            data-container="body" data-toggle="tooltip" data-placement="bottom" title="Affichage plein écran"
          >
            <i
              className={'fa-solid fa-expand'}
              style={{
                fontSize: 32,
                color: '#909090',
              }}
            ></i>
            
          </div>
        )}
        {isFullScreen && (
          <div
            onClick={() => {
              if (onSwitchFullScreen) {
                onSwitchFullScreen()
              }
              if (fscreenHandle) {
                fscreenHandle.exit()
              }
            }}
            className={'cursor-pointer'}
          >
            <i
              className={'fa-solid fa-minimize'}
              style={{
                fontSize: 32,
                color: '#909090',
              }}
            ></i>
          </div>
        )}
        <GetAlert open={open} setOpen={setOpen} />
      </div>
      <div
        style={{
          // paddingBottom: '10%',
          flex: 1,
          paddingTop: isFullScreen ? 50 : 10,
          borderTop: '1.5px solid #000',
          padding: isMobile ? '20px' : '4rem',
        }}
        className={'overflow-auto'}
        id={'scrollableMessage'}
        ref={scrollableRef}
      >
        {messages.map((message, index) => {
          const userInfo = userInfos[message.user]
          const state = message.type === 'in' ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.type === 'in' ? 'start' : 'end'
          } mb-10`
          return (
            <>
              <div
                key={`message${index+index}`}
                className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
                {...templateAttr}
               >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                 >
                  <div className='d-flex align-items-center mb-2'>
                    {message.type === 'in' ? (
                      <>
                        <div className='ms-3'>
                          <div className='fs-5 fw-bold text-gray-900 me-1'>{message?.username}</div>
                          <span
                            className='text-muted fs-7 mb-1 text-gray-400'
                            title={
                              message?.time
                                ? 'Le ' +
                                  moment(message?.time).format('DD/MM/YYYY') +
                                  ' à ' +
                                  moment(message?.time).format('HH') +
                                  'h' +
                                  moment(message?.time).format('mm')
                                : ''
                            }
                          >
                            {calculatePassTime(message?.time, message?.ServerDate)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='me-3 text-end'>
                          <div className='fs-5 fw-bold text-gray-900'>
                            {message?.ConnectedUser
                              ? 'Vous'
                              : message?.ConnectedUser === false &&
                                message?.Side === 'Right' &&
                                message?.username}
                          </div>
                          <div>
                            <span className={'text-gray-700'}>
                              {message?.ContentType === 'Text' && message?.seen
                                ? ' (Lu) '
                                : message?.ContentType === 'Text' && '(Non lu) '}
                            </span>
                            <span
                              className='text-muted fs-7 mb-1 text-gray-400'
                              title={
                                message?.time
                                  ? 'Le ' +
                                    moment(message?.time).format('DD/MM/YYYY') +
                                    ' à ' +
                                    moment(message?.time).format('HH') +
                                    'h' +
                                    moment(message?.time).format('mm')
                                  : ''
                              }
                            >
                              {calculatePassTime(message?.time, message?.ServerDate)}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-semibold mw-lg-400px',
                      `text-${message.type === 'in' ? 'start' : 'end'}`,
                      `${message.type === 'in' ? 'left-buble' : 'right-buble'}`
                    )}
                    data-kt-element='message-text'
                    // dangerouslySetInnerHTML={{__html: message.text}}
                  >
                    <div className={'d-flex align-items-center'}>
                      {message?.ContentType === 'File' &&
                        message?.seen === false &&
                        message?.ConnectedUser === true && (
                          <div className={'text-start text-danger p-3'}>
                            <div className={'fw-bold'}>Important :</div>
                            <div>
                              Pour des raisons de sécurité, les fichiers échangés sont supprimés du
                              fil de discussion après le téléchargement par leur destinataire ou
                              s'ils ont été attachés depuis plus de 24h.
                            </div>
                          </div>
                        )}
                      {message?.ContentType === 'File' &&
                        message?.seen === true &&
                        message?.ConnectedUser === true && (
                          <div className={'text-end p-3'}>
                            <div style={{color: '#CE9070'}}>
                              {'Le fichier "' +
                                message?.text +
                                '" a été téléchargé (ou supprimé automatiquement après 24h pour des raison de sécurité)'}
                            </div>
                          </div>
                        )}
                      {message?.ContentType === 'File' &&
                        message?.seen === true &&
                        message?.ConnectedUser === false && (
                          <div className={'text-start p-3'}>
                            <div style={{color: '#CE9070'}}>
                              {'Le fichier "' +
                                message?.text +
                                '" a été téléchargé (ou supprimé automatiquement après 24h pour des raison de sécurité)'}
                            </div>
                          </div>
                        )}
                      <div>
                        {message?.ContentType === 'File' && message?.seen === false && (
                          <div
                            className={'w-100 d-flex justify-content-center cursor-pointer'}
                            onClick={() => {
                              getStreamWithFetch(message?.FileId || '', message.text, (res) => {
                                if (onDownLoaded) {
                                  onDownLoaded()
                                }
                              })
                            }}
                          >
                            <i
                              className='fas fa-file-download text-black'
                              style={{fontSize: 32}}
                            ></i>
                          </div>
                        )}
                        {(message?.ContentType === 'Text' ||
                          (message?.ContentType === 'File' && message.seen === false)) && (
                          <div
                            style={
                              message?.ContentType === 'File'
                                ? {
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 1,
                                    borderBottomColor: '#00000080',
                                  }
                                : {}
                            }
                            onClick={() => {
                              if (message?.ContentType !== 'File') return
                              getStreamWithFetch(message?.FileId || '', message.text, (res) => {
                                if (onDownLoaded && message?.ContentType === 'File') {
                                  onDownLoaded()
                                }
                              })
                            }}
                            className={clsx({'cursor-pointer ': message?.ContentType === 'File'})}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: cleanMessage(message.text?.replaceAll('\n', '<br/>')),
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                      {message?.ContentType === 'File' &&
                        message?.seen === false &&
                        message?.ConnectedUser === false && (
                          <div className={'text-start text-danger p-3'}>
                            <div className={'fw-bold'}>Important :</div>
                            <div>
                              Pour des raisons de sécurité, les fichiers échangés sont supprimés du
                              fil de discussion après leur téléchargement par le destinataire et/ou
                              passé un delai de 24h.
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {index === messages?.length - 1 && <div ref={bottomRef} id={message?.Id} />}
            </>
          )
        })}
        {/* <div ref={bottomRef}></div> */}
      </div>

      <div
        className='card-footer'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
        style={{
          bottom: 20,
          width: '100%',
          height: 100,
          paddingLeft: isMobile ? '20px' : '4rem',
          paddingRight: isMobile ? '20px' : '4rem',
        }}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Saisissez votre texte'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
          style={{backgroundColor: '#ececec'}}
          disabled={loading}
          ref={inputRef}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
              onClick={onClickFileButton}
            >
              <i className='fa-solid fa-paperclip'></i>
              <input
                ref={inputFile}
                type={'file'}
                onChange={onFileChange}
                style={{width: 0, height: 0, opacity: 0, overflow: 'hidden'}}
                placeholder=''
              />
            </button>
            {!file && (
              <div
                className={'d-flex align-items-center cursor-pointer ps-1'}
                onClick={() => {
                  inputFile?.current?.click()
                }}
              >
                <div>{'Joindre un fichier'}</div>
              </div>
            )}
            {file && (
              <div className={'d-flex align-items-center ps-1'}>
                <div>{file.name}</div>
                <div className={'mx-4 p-4 cursor-pointer'} onClick={onRemoveFile}>
                  <i className='fa-solid fa-close text-danger'></i>
                </div>
              </div>
            )}
          </div>
          <button
            className='btn btn-primary rounded-circle'
            style={{
              width: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
            disabled={loading}
          >
            <img
              src={toAbsoluteUrl('/media/images/Send_icone.png')}
              alt=''
              style={{width: '20px'}}
            />
          </button>
        </div>
      </div>
      <div className={'d-none d-sm-none d-md-flex d-lg-flex d-xl-flex'} style={{height: '2%'}} />
    </div>
  )
}

export {ChatCustom}

import React, {useMemo} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Button} from 'react-bootstrap'
import {useQuery} from 'react-query'
import {ALERT_URL, getAlert} from '../../modules/alert/core/Requests'
import moment from 'moment'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


function GetAlert({open, setOpen}: {open: boolean; setOpen: Function}) {
  const {data, isFetched} = useQuery<any>(ALERT_URL, getAlert, {enabled: open})

  console.log('data', data)
  const date: any = useMemo(() => {
    if (open && isFetched && data && data?.data && data?.data?.CreatedAt) {
      if (moment(data?.data?.CreatedAt)?.isValid()) {
        return (
          moment(data?.data?.CreatedAt).format('DD/MM/YY') +
          ' ' +
          moment(data?.data?.CreatedAt).format('HH') +
          'H' +
          moment(data?.data?.CreatedAt).format('mm')
        )
      }
    }
    return null
  }, [data, isFetched, open])
  return (
    <Modal show={open} onHide={() => setOpen(false)} size={'xl'}>
      <Modal.Header closeButton>
        <img src={toAbsoluteUrl('/media/images/alert_icone.png')} alt="Logo" style={{width: '25px'}}/>
        {/* <i
          className='fa-solid fa-file'
          style={{
            fontSize: 32,
            color: '#909090',
            transform: 'rotateY(180deg)',
          }}
        ></i> */}
        <Modal.Title className='mx-3'>Résumé de l'Alerte {date ? `(${date})` : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFetched && data?.data && (
          <div 
          className='d-lg-flex gap-10 d-md-flex d-xl-flex flex-sm-column flex-xl-row  justify-content-xl-between justify-content-lg-between justify-content-md-between justify-content-sm-start'
          style={{ flex: '1'}}
          >
            <div style={{flex: '0.5'}}>
              <div className='mb-6'>
                <div className={'fw-bolder'}>Catégorie de manquement</div>
                <div className={'fw-light'}>{data?.data?.q3}</div>
                <div className={'fw-bold'}>{data?.data?.r3}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-bolder'}>
                  Description de signalement et transmission de documents
                </div>
                <div className={'fw-light'}>{data?.data?.q5}</div>
                <div className={'fw-bold'}>{data?.data?.r5}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q6}</div>
                <div className={'fw-bold'}>{data?.data?.r6}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q7}</div>
                <div className={'fw-bold'}>{data?.data?.r7}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q8}</div>
                <div className={'fw-bold'}>{data?.data?.r8}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q9}</div>
                <div className={'fw-bold'}>{data?.data?.r9}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q10}</div>
                <div className={'fw-bold'}>{data?.data?.r10}</div>
              </div>
            </div>
            <div style={{flex: '0.5'}}>
              <div>
                <p className={'fw-bolder'}>Identification de l'auteur du signalement</p>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q14}</div>
                <div className={'fw-bold'}>{data?.data?.r14}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q15}</div>
                <div className={'fw-bold'}>{data?.data?.r15}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q16}</div>
                <div className={'fw-bold'}>{data?.data?.r16}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q17}</div>
                <div className={'fw-bold'}>{data?.data?.r17}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q18}</div>
                <div className={'fw-bold'}>{data?.data?.r18}</div>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q19}</div>
                <div className={'fw-bold'}>{data?.data?.r19}</div>
              </div>
              <div className={'fw-light'}>
                <p className={'fw-bolder'}>Référents étique traitant votre signalement</p>
              </div>
              <div className='mb-6'>
                <div className={'fw-light'}>{data?.data?.q22}</div>
                <div className={'fw-bold'}>{data?.data?.r22}</div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='w-100 text-center'>
          <Button className={'w-150px p-3 btn btn-primary btn-sm'} onClick={() => setOpen(false)}>
            Fermer
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default GetAlert

import React from 'react'
import { useNavigate } from 'react-router-dom'

function AboutPage() { 
 const navigate = useNavigate()
 const back = () => {
  navigate(-1)
 } 
  return (
    <div className={'container'}>
      <div className={'row'}>
        <div className={'h-100 d-flex flex-column align-items-center justify-content-center position-relative mt-10'}>
          <h1>À propos de SFL</h1>
          <p>Ceci est la page À propos.</p>
        </div>
        <div className={'position-absolute py-5 px-auto'}>
          <i onClick={back} className="bi bi-caret-left fs-2qx"></i>
        </div>
      </div>
    </div>
  )
}

export default AboutPage

import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

export const ALERT_URL = `${API_URL}/front/GetAlert`
export const getAlert = () => {
  return axios.get(
    ALERT_URL
  ).then(response => response.data)
}

import {AxiosStatic} from 'axios'
import {UserAuthModel} from './_models'
import {getUserByToken, refreshToken} from './_requests'


import Cookies from 'js-cookie';
// @ts-ignore
import CryptoJS from 'crypto-js';
import {pass} from "../../../../common/constants/fileconf";

const AUTH_LOCAL_STORAGE_KEY = 'kt-conf-react-v'
const getAuth = (): UserAuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | undefined | null = Cookies.get(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
   // const auth: UserAuthModel = JSON.parse(lsValue) as UserAuthModel
      if (lsValue) {
          const bytes = CryptoJS.AES.decrypt(lsValue, pass);
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
         // console.log('decryptedData', typeof  decryptedData);
          if (typeof decryptedData == "string") {
            //  console.log('decryptedData', JSON.parse(decryptedData));
              return  JSON.parse(decryptedData) as UserAuthModel
          }

      }
    // if (auth) {
    //   // You can easily check auth_token expiration also
    //   return auth
    // }
      return  undefined
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: UserAuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(lsValue), pass).toString();
    Cookies.set(AUTH_LOCAL_STORAGE_KEY, encryptedData)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setToken = (token:string) => {
    Cookies.set('authToken', token, { secure: true, sameSite: 'Strict',});
};

const getToken = () => {
    return Cookies.get('authToken');
};

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    Cookies.remove(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export const AxiosSetup = (axios: AxiosStatic) => {
  // axios.defaults.headers = {}
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      const appVersion = process.env.REACT_APP_VERSION || '1.0'
      const authUser = getAuth()
      const headers = {...config.headers}
      headers['Cache-Control'] = 'no-cache'
      headers['Pragma'] = 'no-cache'
      headers['Expires'] = '0'
      headers['AppVersion'] = appVersion
      const API_KEY = process.env.REACT_APP_API_KEY
      headers['ApiKey'] = API_KEY ? atob(API_KEY).toString() : ''
      headers['Key'] = authUser?.Key || headers['Key'] || ''
      if (!config.url?.includes('AuthenticateUser')) {
        headers['AccessToken'] = headers['AccessToken'] || authUser?.AccessToken || ''
      }

      return {...config, headers: headers}
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )
  axios.interceptors.response.use(
    async (response) => {
      if (response.data?.Status?.FailureReason === 'AccessToken Expired') {
        const freshToken = await refreshToken()

        if (freshToken?.data?.AccessToken) {
          const userByToken = await getUserByToken(freshToken?.data?.AccessToken)
          console.log('userByToken', freshToken?.data?.AccessToken)
          if (userByToken && userByToken?.data) {
            setAuth(userByToken?.data)
          }
        }
      }
      return response
    },
    (error) => {
      return {errorMessage: 'Network error'}
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY,setToken,getToken}

import clsx from 'clsx'
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'
// import {MessageModel, toAbsoluteUrl} from '../../../_metronic/helpers'
import {ChatInner, HeaderUserMenu} from '../../../_metronic/partials'
import {ChatCustom} from '../../modules/apps/chat/components/ChatCustom'
import {Private} from '../../modules/apps/chat/components/Private'
import AboutPage from '../about/AboutPage'
import {Link} from 'react-router-dom'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../../_metronic/helpers'
import {getAuth, getToken, useAuth} from '../../modules/auth'
import {
  getListMessage,
  postFile,
  postMessage,
  UPLOAD_FILE_URL,
} from '../../modules/apps/chat/core/_request'
import {FullScreen, FullScreenHandle, useFullScreenHandle} from 'react-full-screen'
import DOMPurify from "dompurify";
const bufferMessages = defaultMessages
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const ChatWrapper = () => {
  const {currentUser, saveAuth, logout} = useAuth()
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [messages, setMessages] = useState<MessageModel[]>([])
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const [sended, setSended] = useState<number>(1)
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [sentMessage, setSentMessage] = useState<string>('')
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

  const sendMessage = async (message: string, callback?: () => void) => {
    setLoading(true)
    if (message && message !== '') {
      const cleanMessage = DOMPurify.sanitize(message);
      const sendRes = await postMessage(cleanMessage)
      if (sendRes && sendRes?.Status && sendRes?.Status?.Success) {
        console.log('message', message)
        setSentMessage(message)
      }
    }
    await handleUploadClick()
    setSended(sended + 1)
    setLoading(false)
    if (callback) {
      callback()
    }
    
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const handleUploadClick = async () => {
    console.log('Handle file upload')
    if (!file) {
      return
    }
    const API_KEY = process.env.REACT_APP_API_KEY
    const appVersion = process.env.REACT_APP_VERSION || '1.0'
    const authUser = getAuth()
    const bodyXForm = new FormData()
    bodyXForm.append('data', JSON.stringify({File: {FileName: file.name}}))
    bodyXForm.append('file', file)
    return await fetch(UPLOAD_FILE_URL + '?T2=' + new Date().getTime(), {
      method: 'POST',
      body: bodyXForm,
      headers: {
        AppVersion: appVersion,
        ApiKey: API_KEY ? atob(API_KEY).toString() : '',
        AccessToken: authUser?.AccessToken || '',
      },
    })
      .then(async (res) => {
        const resJson = await res.json()
        console.log('resJson file ', resJson)
        if (resJson && resJson?.data && resJson?.data?.File && resJson?.data?.File?.Guid) {
          setFile(null)
          const newFile = resJson?.data?.File
          await postFile({Id: newFile?.Guid, FileName: newFile?.FileName})
        }
        return resJson
      })
      .then((data) => console.log(data))
      .catch((err) => console.error(err))
  }

  const loadMessage = async () => {
    const messageRes = await getListMessage()
    if (
      messageRes?.Status?.FailureReason === 'Access lost (connection to another device detected)'
    ) {
      // saveAuth(undefined)
      logout()
    } else if (messageRes && messageRes?.data && messageRes?.data?.length > 0) {
      // console.log('messageRes', messageRes)
      const msges: MessageModel[] = []
      messageRes?.data?.forEach((msg: any, i: number) => {
        msges.push({
          text: msg?.ContentText,
          time: msg?.Date, // msg?.Date,
          type: msg?.ConnectedUser || (!msg?.ConnectedUser && msg?.Side === 'Right') ? 'out' : 'in',
          user: msg?.ConnectedUser ? 1 : 2,
          seen: msg?.Seen,
          username: msg?.Sender,
          ContentType: msg?.ContentType,
          FileId: msg?.FileId,
          ConnectedUser: msg?.ConnectedUser,
          Id: msg?.Id,
          Side: msg?.Side,
        })
      })
      setMessages(msges)
    }
  }

  const onRemoveFile = () => {
    setFile(null)
  }

  useEffect(() => {
    const inetrChatUpdate = setInterval(() => {
      loadMessage()
    }, 60000)
    return () => {
      clearInterval(inetrChatUpdate)
    }
  }, [])

  useEffect(() => {
    loadMessage()
  }, [sended])

  const screen1 = useFullScreenHandle()

  const reportChange = useCallback(
    (state: boolean, handle: FullScreenHandle) => {
      if (handle === screen1) {
        console.log('Screen 1 went to', state, handle)
        // setIsFullScreen(state)
        // if (isFullScreen && state === false) {
        //   setIsFullScreen(false)
        // }
      }
    },
    [screen1]
  )
  return (
    <FullScreen handle={screen1} onChange={reportChange} className={'h-100 w-100'}>
      <div
        className='col-12 overflow-hidden d-flex flex-column chat-container '
        style={{maxHeight: '95vh', height: '95%', backgroundColor: '#FFFFFF'}}
      >
        { (
          <div
            className={
              'topbar d-flex align-items-stretch flex-shrink-0 position-relative ' +
              ' col-xs-12 col-sm-12 col- col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between align-items-center overflow-hidden'
            }
            style={{
              zIndex: 999,
              backgroundColor: '#FFFFFF',
              height: screen1.active ? 0 : 'auto',
            }}
          >
            <div
              className='d-none d-sm-none d-md-none d-lg-flex d-xl-flex p-0'
              style={{width: '10%'}}
            ></div>
            <div
              className='d-flex d-sm-flex d-md-flex d-lg-none d-xl-none'
              style={{width: 20}}
            ></div>
            <div
              className='d-flex justify-content-between align-items-center py-5'
              style={{flex: 1}}
            >
              <img
                src={toAbsoluteUrl('/media/images/logo.png')}
                alt={'LOGO'}
                width={'8%'}
                height={'auto'}
                style={{minWidth: 80}}
                className={'d-none d-sm-none d-md-none d-lg-inline d-xl-inline'}
              />
              <img
                src={toAbsoluteUrl('/media/images/logo.png')}
                alt={'LOGO'}
                // width={'8%'}
                height={'auto'}
                style={{width: 60}}
                className={'d-inline d-sm-inline d-md-inline d-lg-none d-xl-none'}
              />
              <div className='d-none d-sm-none d-md-none d-lg-flex d-xl-flex'>
                <span
                  className='text-uppercase text-gray-700'
                  style={{
                    fontSize: 20,
                    fontWeight: 'normal',
                  }}
                >
                  {'Espace sécurisé - '}
                </span>
                <span
                  className='text-uppercase text-black'
                  style={{
                    fontSize: 20,
                    fontWeight: 'bolder',
                    marginLeft: '7px',
                  }}
                >
                  {" lanceur d'alertes"}
                </span>
              </div>

              <div className='d-flex align-items-center text-black'>
                <span className='me-3'>{(currentUser?.FirstName ? currentUser?.FirstName : '') + ' ' + (currentUser?.LastName ? currentUser?.LastName : '')}</span>
                <div
                  className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                  id='kt_header_user_menu_toggle'
                >
                  <div
                    className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                  >
                    <div style={{padding: 5}}>
                      <div
                        style={{
                          width: 3,
                          height: 3,
                          borderRadius: 10,
                          backgroundColor: '#000',
                          margin: 2,
                        }}
                      />
                      <div
                        style={{
                          width: 3,
                          height: 3,
                          borderRadius: 10,
                          backgroundColor: '#000',
                          margin: 2,
                        }}
                      />
                      <div
                        style={{
                          width: 3,
                          height: 3,
                          borderRadius: 10,
                          backgroundColor: '#000',
                          margin: 2,
                        }}
                      />
                    </div>
                  </div>
                  <HeaderUserMenu />
                  {/* end::Toggle */}
                </div>
              </div>
            </div>
            <div
              className='d-flex d-sm-flex d-md-flex d-lg-none d-xl-none'
              style={{width: 20}}
            ></div>
            <div
              className='d-none d-sm-none d-md-none d-lg-flex d-xl-flex p-0'
              style={{width: '10%'}}
            ></div>
          </div>
        )}

        <div
          className={
            'col-xs-12 col-sm-12 col- col-md-12 col-lg-12 col-xl-12 row d-flex overflow-hidden position-relative p-0 m-0'
          }
          style={{
            flex: 1,
            zIndex: 1,
            
          }}
        >
          {!screen1.active && (
            <div
              className='d-none d-sm-none d-md-none d-lg-flex d-xl-flex p-0'
              style={{width: '10%'}}
            ></div>
          )}
          <div
            className='row d-flex h-100 mh-100 overflow-hidden d-sm-flex d-md-flex d-lg-none d-xl-none p-0 m-0'
            style={{flex: 1}}
          >
            {!screen1.active && (
              <div
                className='col-md-4 col-lg-4 col-xl-4 d-none d-sm-none d-md-none d-lg-flex d-xl-flex p-4'
                style={{maxHeight: '100%'}}
              >
                <div className={'left-of-chat col-12'}></div>
              </div>
            )}
            <div
              className={
                'col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 d-flex overflow-hidden chat-mobile p-0'
              }
              style={{maxHeight: '100%', backgroundColor: '#FFFFFF'}}
            >
              <div
                className={'col-12 d-flex flex-column overflow-hidden'}
                style={{maxHeight: '92%'}}
              >
                <ChatCustom
                  onSendMessage={sendMessage}
                  messages={messages}
                  file={file}
                  onFileChange={handleFileChange}
                  isTextSent={sentMessage}
                  onRemoveFile={onRemoveFile}
                  loading={loading}
                  isFullScreen={screen1.active}
                  onSwitchFullScreen={() => {
                    setIsFullScreen(true)
                    // screen1.enter()
                    if (screen1.active) {
                      screen1.exit()
                    } else {
                      screen1.enter()
                    }
                  }}
                  onDownLoaded={() => {
                    loadMessage()
                  }}
                  isMobile={true}
                  fsHandle={screen1}
                  fsReportChange={reportChange}
                />
              </div>
            </div>
          </div>
          <div
            className='row d-flex overflow-hidden d-none d-sm-none d-md-none d-lg-flex d-xl-flex p-0'
            style={{flex: 1, maxHeight: '100%'}}
          >
            {!screen1.active && (
              <div
                className='col-md-4 col-lg-4 col-xl-4 d-none d-sm-none d-md-none d-lg-flex d-xl-flex'
                style={{maxHeight: '100%'}}
              >
                <div className={'left-of-chat col-12'}></div>
              </div>
            )}
            <div
              className={
                'col-xs-12 col-sm-12 col-md-12  p-1 d-flex overflow-hidden m-0' +
                (screen1.active ? 'col-lg-12 col-xl-12' : 'col-lg-8 col-xl-8')
              }
              style={{maxHeight: '100%'}}
            >
              <div
                className={
                  'col-12 d-flex flex-column overflow-hidden ' +
                  (screen1.active ? 'chat-mode' : 'chat-message')
                }
                style={{maxHeight: '100%', backgroundColor: '#FFFFFF'}}
              >
                <ChatCustom
                  onSendMessage={sendMessage}
                  messages={messages}
                  file={file}
                  onFileChange={handleFileChange}
                  isTextSent={sentMessage}
                  onRemoveFile={onRemoveFile}
                  loading={loading}
                  isFullScreen={screen1.active}
                  onSwitchFullScreen={() => {
                    setIsFullScreen(true)
                    // screen1.enter()
                    if (screen1.active) {
                      screen1.exit()
                    } else {
                      screen1.enter()
                    }
                  }}
                  onDownLoaded={() => {
                    loadMessage()
                  }}
                  fsHandle={screen1}
                  fsReportChange={reportChange}
                />
              </div>
            </div>
          </div>
          {!screen1.active && (
            <div
              className='d-none d-sm-none d-md-none d-lg-flex d-xl-flex p-0'
              style={{width: '10%'}}
            ></div>
          )}
        </div>
        <div className={'d-flex'}>
          <div
            className='d-none d-sm-none d-md-none d-lg-flex d-xl-flex p-0'
            style={{width: '10%'}}
          ></div>
          <div
            className='d-flex d-sm-flex d-md-flex d-lg-none d-xl-none p-0'
            style={{width: 20}}
          ></div>
          <div 
            className={'d-flex align-items-center justify-content-between'}
            style={{flex: 1}}
          >
            <div>
              <span className={'text-gray-500'}>© Société Foncière Lyonnaise</span>
            </div>
            <div>
              <Link to='/AboutPage'>
                <p className={'text-gray-500'} style={{display: 'none'}}>
                  <ins>A propos ...</ins>
                </p>
              </Link>
            </div>
          </div>
          <div
            className='d-flex d-sm-flex d-md-flex d-lg-none d-xl-none p-0'
            style={{width: 20}}
          ></div>
          <div
            className='d-none d-sm-none d-md-none d-lg-flex d-xl-flex p-0'
            style={{width: '10%'}}
          ></div>
        </div>
      </div>
    </FullScreen>
  )
}

export default ChatWrapper

import moment from 'moment'
const days = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
]
const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]
export const calculatePassTime = (date: string, dnow?: string) => {
  const td = new Date(date)
  const nd = dnow ? new Date(dnow) : new Date()
  const theDate = td.getTime()
  const nowDate = nd.getTime()
  const diff = nowDate - theDate
  const nbSecond = diff / 1000
  if (nbSecond < 60) {
    return 'À l\'instant'
  } else {
    const nbMinute = (nbSecond - (nbSecond % 60)) / 60
    if (nbMinute < 60) {
      return 'Il y a ' + nbMinute + (nbMinute > 1 ? ' minutes' : ' minute')
    } else {
      const nbHour = (nbMinute - (nbMinute % 60)) / 60
      const yesterday = new Date(nd)
      yesterday.setSeconds(59)
      yesterday.setMinutes(59)
      yesterday.setHours(23)
      yesterday.setDate(nd.getDate() - 1)
      
      
      
      const beforey = new Date(nd)
      beforey.setSeconds(59)
      beforey.setMinutes(59)
      beforey.setHours(23)
      beforey.setDate(nd.getDate() - 2)
      
      
      
      if (td.getTime() < beforey.getTime()) {
        return days[td.getDay()] + ' ' + td.getDate() + ' ' + months[td.getMonth()] + ' - ' +
        (td.getHours() < 10 ? ('0' + td.getHours()) : td.getHours()) +
        'h' + (td.getMinutes() < 10 ? ('0' + td.getMinutes()) : td.getMinutes())
      } else if (td.getTime() < yesterday.getTime()) {
        return 'Hier à ' + (td.getHours() < 10 ? ('0' + td.getHours()) : td.getHours()) +
        'h' + (td.getMinutes() < 10 ? ('0' + td.getMinutes()) : td.getMinutes())
      }  else {
        return 'Il y a ' + nbHour + (nbHour > 1 ? ' heures ' : ' heure ')
      }
    }
  }
}
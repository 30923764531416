import React, {FC, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Button} from 'react-bootstrap'
import {useQuery} from 'react-query'
import moment from 'moment'

type LEMProps = {
  open: boolean; 
  onClose: () => void;
  message?: string;
}

const LoginErrorModal: FC<LEMProps> = ({
  open,
  onClose,
  message,
}) => {
  return (
    <Modal show={open} onHide={onClose} size={'sm'} centered>
      <Modal.Body >
        <div className='text-center'>
          <div style={{
            width: 40,
            height: 40,
            border: '1.5px solid #4baec0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            margin: 'auto',
            marginTop: 25,
            marginBottom: 30,
          }}>
            <i className="fa-solid fa-xmark" style={{fontSize: 32, color: '#4baec0'}}></i>
          </div>
          <div >
            {message}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='w-100 text-center'>
          <Button className={'w-150px p-3 btn btn-primary btn-sm'} onClick={onClose} style={{backgroundColor: '#4baec0'}}>
            Ok, j'ai compris!
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default LoginErrorModal

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import AboutPage from '../../../pages/about/AboutPage'
import ErrorModal from './ErrorModal'
import LoginErrorModal from './LoginErrorModal'
import {setToken} from "../core/AuthHelpers";

const loginSchema = Yup.object().shape({
  Key: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Longueur de la clé trop courte')
    // .max(50, 'Maximum 50 symbols')
    .required('La clé est obligatoire'),
  Password: Yup.string()
    .min(3, 'Mot de passe doit contenir au moins 3 caractères')
    .max(50, 'Mot de passe doit être moins de 50 caractères')
    .required('Mot de passe est obligatoire'),
})

const initialValues = {
  Key: '',
  Password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  // const param = useParams()
  // const togglerRef: any = useRef(null)
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [openError, setOpenError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  
  const queryParameters = new URLSearchParams(window.location.search)
  const defaultKey = queryParameters.get("key")
  console.log('Param : ', defaultKey)

  

  const formik = useFormik({
    initialValues: {...initialValues, Key: ( defaultKey ? defaultKey : '')},
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      if (formik.errors.Key || formik.errors.Password) {
        setOpenError(true)
        setErrorMessage('Clé et/ou mot de passe erronés ou compte inconnu')
        return
      }
      try {
        console.log('Key :', values.Key)
        const auth = await login(values.Key, values.Password)
        if (auth && auth?.data && auth?.data) {
          console.log(auth?.data)
          saveAuth(auth?.data)
          // setToken(auth?.data?.AccessToken)
          setCurrentUser(auth?.data)
        } else {
          // document?.getElementById('errorToggler')?.click()
          // togglerRef.current?.click()
          setOpenError(true)
          setErrorMessage(
            'Désolé, on dirait que des erreurs ont été détectées, veuillez réessayer.'
          )
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        // setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        setOpenError(true)
        setErrorMessage('Clé et/ou mot de passe erronés ou compte inconnu')
      }
    },
  })

  return (
    <div className={'d-flex flex-column '} style={{maxHeight: '100%'}}>
      <div
        className={'row col-12 overflow-hidden p-0 m-0'}
        style={{maxHeight: '100%', backgroundColor: '#FFFFFF', flex: 1}}
      >
        <div
          style={{minHeight: '94vh', backgroundColor: '#FFFFFF'}}
          className={
            'h-100 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex flex-column align-items-center justify-content-center position-relative p-0 m-0'
          }
        >
          <div style={{flex: 1}} />
          <img src={toAbsoluteUrl('/media/images/logo.png')} alt={'LOGO'} className={'w-25'} />
          <form
            className='form w-75 '
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='mb-6'>
              <div style={{height: '3rem'}}></div>
              <div
                className='text-center text-gray-600 fw-semibold pt-5 pb-5'
                style={{fontSize: 18}}
              >
                {"Bienvenue dans l'Espace sécurisé d'échange \" Lanceur d'Alertes \""}
              </div>
              <div style={{height: '3rem'}}></div>
              <h1 className='text-black fw-bolder mb-1'>S'identifier</h1>
              <div className='text-gray-500 fw-400 fs-8'>Pour échanger sur les alertes ...</div>
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-black'>
                Clé sécurisée personnelle
              </label>
              <input
                placeholder='Clé sécurisée personnelle'
                {...formik.getFieldProps('Key')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.Key && formik.errors.Key},
                  {
                    'is-valid': formik.touched.Key && !formik.errors.Key,
                  }
                )}
                type={'text'}
                name='Key'
                autoComplete='off'
              />
              {formik.touched.Key && formik.errors.Key && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.Key}</span>
                </div>
              )}
            </div>
            <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-black fs-6 mb-0'>Mot de passe</label>
              <input
                type='password'
                placeholder='Mot de passe'
                autoComplete='off'
                {...formik.getFieldProps('Password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.Password && formik.errors.Password,
                  },
                  {
                    'is-valid': formik.touched.Password && !formik.errors.Password,
                  }
                )}
              />
              {formik.touched.Password && formik.errors.Password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.Password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex justify-content-end'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                <span className='indicator-label'>S'identifier</span>
                {/* {!loading && }
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Veuillez patienter ...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
              </button>
            </div>
          </form>
          <div style={{flex: 1}} />
          <div className={'d-flex align-items-center justify-content-between w-75'}>
            <div>
              <span className={'text-gray-500'}>© Société Foncière Lyonnaise</span>
            </div>
            <div>
              <Link to='/AboutPage'>
                <ins className={'text-gray-500'} style={{display: 'none'}}>A propos ...</ins>
              </Link>
            </div>
          </div>
          <div style={{height: 30}} />
        </div>
        <div
          className={
            'col-6 d-none d-sm-none d-md-none d-lg-flex d-xl-flex align-items-center overflow-hidden p-0 m-0'
          }
        >
          <img
            src={toAbsoluteUrl('/media/images/bgLogin.png')}
            alt={'BG_IMAGE'} 
            style={{minWidth:'100%',minHeight:'100%'}}
          />
        </div>
        <LoginErrorModal
          open={openError}
          onClose={() => {
            setOpenError(false)
            setErrorMessage('')
          }}
          message={errorMessage}
        />
      </div>
    </div>
  )
}

import axios from 'axios'
import qs from 'qs'
import { getAuth } from './AuthHelpers'
import {AuthModel, TestUser, UserAuthModel, UserModel} from './_models'

const userData = {
  "Guid": "5266ed68-ab02-45c5-b4df-0d7b40df092d",
  "FirstName": "Sébastien",
  "LastName": "MOULIGNEAUX",
  "Profile": "Lanceur d'alerte",
  "Email": "test@test.com",
  "Key": "uKJbMpUYQPyxk8P8MVbptB+JpDETd7Pqnjkyy9AngNc=",
  "AccessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiI1MjY2ZWQ2OC1hYjAyLTQ1YzUtYjRkZi0wZDdiNDBkZjA5MmQiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiU8OpYmFzdGllbiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL3N1cm5hbWUiOiJNT1VMSUdORUFVWCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InRlc3RAdGVzdC5jb20iLCJuYmYiOjE2OTA0NDAyMDEsImV4cCI6MTY5MDQ0MDgwMX0.CFioeS-ALyuploVkzbHXbQvr0obmS_NUYXcydJnuZMg",
  "RefreshToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3NlcmlhbG51bWJlciI6ImM3MzU5OWE4LTIxNjgtNDNmYy04ZTYwLTI3OGQ1NTIwNmE1YyIsIm5iZiI6MTY5MDQ0MDIwMSwiZXhwIjoxNjkwNDQwODAxfQ.dYRmRJnpbweWOl8FE9Wgu14UVgCkr3mO7AaZUVhGhuA",
  "TokenExpiration": "2023-07-27T08:53:21.3231834+02:00"
}

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/Authentication/AuthenticateUser`
export const LOGOUT_URL = `${API_URL}/Authentication/DisconnectUser`
export const GET_REFRESH_TOKEN_URL = `${API_URL}/Authentication/RefreshToken?RefreshToken=`
export const GET_USER_BY_TOKEN = `${API_URL}/Authentication/GetUserByToken`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(key: string, password: string): Promise<any> {
  const url = LOGIN_URL + '?Key=' + encodeURIComponent(key) + '&Password=' + password + '&timeto=' + new Date().getTime();
  return axios.get<UserAuthModel>(url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }).then((value) => {
    return value.data
  }).catch((er) => {
    console.log(er)
    return null
  })
}

export function requestLogout(): Promise<any> {
  const url = LOGOUT_URL
  return axios.get<UserAuthModel>(url)
}

export const refreshToken = async () => {
  const auth = getAuth()
  return axios.get(GET_REFRESH_TOKEN_URL + auth?.RefreshToken)
  .then(response => {
    return response.data
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string): Promise<any> {
  return axios.get(GET_USER_BY_TOKEN, {
    headers: {
      'AccessToken': token || ''
    }
  })
  .then(res => res.data)
}

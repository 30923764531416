import axios from "axios"
import { getAuth, setAuth } from "../../../auth"
import { getUserByToken, refreshToken } from "../../../auth/core/_requests"

const API_URL = process.env.REACT_APP_API_URL
export const GET_LIST_MESSAGE_URL = `${API_URL}/front/ListMessage`
export const GET_ALERT_URL = `${API_URL}/front/GetAlert`
export const POST_MESSAGE_URL = `${API_URL}/front/PostMessage`
export const UPLOAD_FILE_URL = `${API_URL}/front/UploadFile`
export const POST_FILE_URL = `${API_URL}/front/PostFile`
export const GET_STREAM_URL = `${API_URL}/Front/stream?Id=`

export const getListMessage = async () => {
  return axios.get(GET_LIST_MESSAGE_URL + '?T2=' + new Date().getTime(), {})
  .then(async (value): Promise<any> => {
    if (value.data?.Status?.FailureReason === 'AccessToken Expired') {
      return await getListMessage()
    }
    return value.data
  })
}

export const getAlert = async () => {
  return axios.get(GET_ALERT_URL + '?T2=' + new Date().getTime(), {})
  .then(async (value): Promise<any> => {
    if (value.data?.Status?.FailureReason === 'AccessToken Expired') {
      return await getAlert()
    }
    return value.data
  })
}

export const postMessage = async (message: string) => {
  return axios.post(POST_MESSAGE_URL + '?T2=' + new Date().getTime(), {
    Content: message
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
  .then(async (value): Promise<any> => {
    if (value.data?.Status?.FailureReason === 'AccessToken Expired') {
      return await postMessage(message)
    }
    return value.data
  })
}

export const uploadFile = async (message: string) => {
  return axios.post(UPLOAD_FILE_URL + '?T2=' + new Date().getTime(), {
    Content: message
  })
  .then(value => value)
}

export const postFile = async ({Id, FileName}: any) => {
  return axios.post(POST_FILE_URL + '?T2=' + new Date().getTime(), {
    Id: Id,
    FileName: FileName
  })
  .then(async (value): Promise<any> => {
    if (value.data?.Status?.FailureReason === 'AccessToken Expired') {
      return await postFile({Id, FileName})
    }
    return value.data
  })
}

export const getStream = (StreamId: string) => {
  return axios.get(GET_STREAM_URL + StreamId + new Date().getTime(), {})
  .then(async (value): Promise<any> => {
    if (value.data?.Status?.FailureReason === 'AccessToken Expired') {
      return await postFile(StreamId)
    }
    return value.data
  })
}

export const getStreamWithFetch = (StreamId: string, fileName: string, onEnd?: (res: {status: boolean}) => void) => {
  const API_KEY = process.env.REACT_APP_API_KEY
    const appVersion = process.env.REACT_APP_VERSION || '1.0'
    const authUser = getAuth()
  fetch(
    GET_STREAM_URL + StreamId + '&T2=' + new Date().getTime(),
    {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        AppVersion: appVersion,
        ApiKey: API_KEY ? atob(API_KEY).toString() : '',
        AccessToken: authUser?.AccessToken || '',
        Key: encodeURIComponent(authUser?.Key || ''),
      }
    }
  ).then(async (res) => {
    // const resJson = await res.json()
    if (res.status === 401) {
      const freshToken = await refreshToken()
      if (freshToken?.data?.AccessToken) {
        const userByToken = await getUserByToken(freshToken?.data?.AccessToken)
        console.log('userByToken', freshToken?.data?.AccessToken)
        if (userByToken && userByToken?.data) {
          setAuth(userByToken?.data)
          setTimeout(() => {
            getStreamWithFetch(StreamId, fileName, onEnd)
          }, 500)
        } else {
          if (onEnd) {
            onEnd({status: false})
          }
        }
      } else {
        if (onEnd) {
          onEnd({status: false})
        }
      }
    } else {
      // const atached = res.headers?.get('content-disposition')
      // console.log('atached', atached)
      // const indexTo = atached ? atached?.indexOf('filename=') + 'filename='.length : 0
      // const fileName = atached?.substring(indexTo, atached?.length)
      var bb = await res?.blob()
      var a = document.createElement('a')
      a.download = fileName || 'fileError.txt'
      const file = window.URL.createObjectURL(bb)
      // window.location.assign(file);
      a.href = file
      a.click()
      if (onEnd) {
        onEnd({status: true})
      }
    }
    
  })
}

// 